import { ICompanyList,
         ICompanyListName,
         ICompanyDetail,
         ICompanyGetListTotalNumRequestParams,
         ICompanyGetListRequestParams,
         ICompanyGetDetailRequestParams,
         ICompanyDetailUpdateRequestParams,
         ICompanyCreateResponseSchema,
         ICompanyDetailUpdateResponseSchema
} from './models/company';
import tpsApiInstance from './tpsApiBase';

const COMPANY_PATH = "/company";

export const apiCompanyGetListTotalNum = async(
  pData?: ICompanyGetListTotalNumRequestParams): Promise<number> => {
    let query: string = "?count=total";
    if(pData && pData.filter) query+= `&filter=${pData.filter}`;

    const res = await tpsApiInstance.get(`${COMPANY_PATH}${query}`);
    console.log("total:",res?.data[0]?.total);
    return res?.data[0]?.total;
};

export const apiCompanyGetList = async(
  pData?: ICompanyGetListRequestParams): Promise<ICompanyList | Blob> => {

  let query: string = "?";
  if(pData && pData.limit && pData.page) query += `limit=${pData.limit}&offset=${(pData.page-1)*pData.limit}`;  
  if(pData && pData.filter) query+= `&filter=${pData.filter}`;

  if(pData && pData.output) {
    query += `&output=${pData.output}`;
    console.log("query:",query);
    const res = await tpsApiInstance.get(`${COMPANY_PATH}${query}`,{responseType: 'blob'});
    return res?.data as Blob;
  }
  else {
    console.log(`${COMPANY_PATH}${query}`);
    const res = await tpsApiInstance.get(`${COMPANY_PATH}${query}`);
    return res?.data as ICompanyList;
  }
};

export const apiCompanyGetListName = async(): Promise<ICompanyListName> => {
  const res = await tpsApiInstance.get(COMPANY_PATH);
  var listName = {};
  if(res.data) {
    var i;
    for (i = 0; i < res.data.length; i++) {
      listName[res.data[i].id] = res.data[i].name;
    }
  }
  return listName as ICompanyListName;
};

export const apiCompanyGetDetail = async(
  pData: ICompanyGetDetailRequestParams): Promise<ICompanyDetail> => {
  const res = await tpsApiInstance.get(`${COMPANY_PATH}/${pData}`);
  //console.log(res.data[0]);
  return res?.data[0] as ICompanyDetail;
};

export const apiCompanyCreate = async(
  pData: ICompanyDetail ): Promise<ICompanyCreateResponseSchema> => {
  console.log(JSON.stringify(pData));
  const res = await tpsApiInstance.post(COMPANY_PATH, pData);
  return res?.data as ICompanyCreateResponseSchema;
};

export const apiCompanyDetailUpdate = async(
  pData: ICompanyDetailUpdateRequestParams
  ): Promise<ICompanyDetailUpdateResponseSchema> => {
  console.log(JSON.stringify(pData.data));
  const res = await tpsApiInstance.post(`${COMPANY_PATH}/${pData.id}`, pData.data);
  return res.data as ICompanyDetailUpdateResponseSchema;
};