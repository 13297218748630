import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert md-alert alert-dismissible", [_ctx.show ? 'show' : 'd-none',_ctx.alertType=='success'?'alert-success':'alert-danger']]),
    style: {"z-index":"1"},
    role: "alert"
  }, [
    _createElementVNode("span", {
      class: _normalizeClass([_ctx.alertType=='success'?'correct-icon':'d-none'])
    }, null, 2),
    _createTextVNode(_toDisplayString(_ctx.msg), 1)
  ], 2))
}