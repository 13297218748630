import tpsApiInstance from "./tpsApiBase";
import {    
    IAccountColumnSettingRequestParams, 
    IAccountColumnSettingUpdateRequestParams,
    IAccountColumnSetting 
} from './models/IAccountColumnSetting';

const SETTING_PATH = "/accountColumnSetting";

export const apiAccountColumnGetSetting = async(
    pData: IAccountColumnSettingRequestParams): Promise<string[] | null> => {
    let query: string = `/${pData.pageNo}`;
    //console.log("query:",query);
    const res = await tpsApiInstance.get(`${SETTING_PATH}${query}`);
    //console.log("get column_setting: :",res.data);
    if(res.data.column_setting) {
        return res.data.column_setting.split(",")
    }
    else {
        return null;
    }
}

export const apiAccountColumnSettingUpdate = async (
    pData: IAccountColumnSettingUpdateRequestParams): Promise<IAccountColumnSetting> => {
    //console.log("set column_setting: "+pData.column_setting);
    let query: string = `/${pData.pageNo}`;
    let body = pData.column_setting.join(",");
    //console.log("set column_setting: "+body);
    const res = await tpsApiInstance.post(`${SETTING_PATH}${query}`, {column_setting:body});
    return res?.data as IAccountColumnSetting;
};
