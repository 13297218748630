
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from "vue-property-decorator";

@Options({
    components: {
    },
    emits: [
      "change-page",
      "change-limit"
    ]
})


export default class Pagination extends Vue {
    @Prop({ required: true }) pTotalNum: number = 0;
    @Prop({ required: true }) pCurrentPage: number = 1;
    @Prop({ required: true }) pLimit: number = 10;
    
    @Emit("change-page")
    changePage(pPage) {
        if(pPage >= 1 && pPage <= Math.ceil(this.pTotalNum/this.pLimit)) {
            return { page: pPage, limit: this.pLimit };
        }
    }

    @Emit("change-limit")
    changeLimit(event): {} {
        const limit = event.target.value;
        return { limit: limit, page: 1 };
    }
}
