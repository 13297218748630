
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from "vue-property-decorator";
import { apiCompanyGetListName } from '../services/tpsApi/tpsApiCompany';
import { apiBrandGetListName } from '../services/tpsApi/tpsApiBrand';
import { apiAccountColumnGetSetting, apiAccountColumnSettingUpdate } from '../services/tpsApi/tpsApiAccountColumnSetting';
import { PAGENO } from '@/services/common/IPageNo.js';
import Alert from './Alert.vue';
import tpsApiInstance from "../services/tpsApi/tpsApiBase";

@Options({
    components: {
        Alert
    },
    emits: ["form"]
})


export default class Table extends Vue {
    @Prop({ required: true }) tTitle!: string[];
    @Prop({ required: true }) tHead!: {};
    @Prop({ required: true }) tData: string[] = [];
    @Prop({ required: false }) tEdit!: boolean;
    @Prop({ required: false }) tClear!: boolean;
    @Prop({ required: false }) tRead!: boolean; //Operating Rule Table
    @Prop({ required: true }) tFilter!: boolean;
    @Prop({ required: false }) tIndex!: boolean;
    @Prop({ required: false }) tCurrentPage!: number;
    @Prop({ required: false }) tCurrentLimit!: number;
    @Prop({ required: false }) tCreateRule!: boolean;
    @Prop({ required: false }) tPageNo!: string;

    $refs!: {
        alert: Alert
    }

    /* Filter */
    showFilter: boolean = false;
    filterDisplay: string = "none";
    filterSelected: string[] = Object.keys(this.tHead);
    filterSelectedDefault: string[] = [];

    WRITE: number | undefined = undefined;
    pageNo: {} = PAGENO;
    companySelectOptions: {} = {};
    brandSelectOptions: {} = {};

    async beforeMount() {
        console.log("write:", this.$store.getters.write);
        this.WRITE = this.$store.getters.write;
        let refilterSelected = await apiAccountColumnGetSetting({ pageNo: this.tPageNo });
        if (refilterSelected) {
            this.filterSelected = refilterSelected.slice();
            this.filterSelectedDefault = refilterSelected.slice();
        }
        else {
            this.filterSelectedDefault = Object.keys(this.tHead);
        }
        this.companySelectOptions = await apiCompanyGetListName();
        this.brandSelectOptions = await apiBrandGetListName();
    }

    convertData(pName, pValue, pList) {
        switch (pName) {
            case "invalid":
                return pValue === 0 ? "啟用" : "停用";
            case "low_income":
                return pValue === 1 ? "是" : "否";
            case "brand_id":
                return this.brandSelectOptions[pValue];
            case "company_id":
                return this.companySelectOptions[pValue];
            case "app_id":
                if (pValue === 1) return "黃通通";
                if (pValue === 2) return "嘉義市復康巴士";
                if (pValue === 3) return "TTGO";
                break;
            case "content": {
                if (pValue && pValue.length > 15) {
                    return pValue.substring(0, 15) + "...";
                }
                else {
                    return pValue;
                }
            }
            case "duration": {
                let date = pList["start_date"];
                let endDate = new Date(date.substring(4, 0),
                    (Number(date.substring(5, 7)) - 1),
                    date.substring(8, 10),
                    date.substring(11, 13),
                    date.substring(14, 16),
                    date.substring(17, 19));
                endDate.setDate(endDate.getDate() + pValue);
                return `${endDate.getFullYear().toString().padStart(2, '0')}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')} ${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}:${endDate.getSeconds().toString().padStart(2, '0')}`;
            }
            case "status":
                if (pValue === 0) return "草稿";
                if (pValue === 1) return "未發佈";
                if (pValue === 2) return "已發佈";
                break;
            case "type":
                if (pValue === 1) return "軟體";
                if (pValue === 2) return "韌體";
                break;
            default:
                return pValue;
        }
    }

    textWrap(pText: string) {
        if (pText) {
            return pText.replace(/\n/g, "<br>");
        }
    }

    //Return disabled value of table edit button
    getDisabledVal(pList: object): boolean {
        if (this.tPageNo == this.pageNo['公告列表']) {
            let date = pList["start_date"];
            let startDate = new Date(date.substring(4, 0),
                (Number(date.substring(5, 7)) - 1),
                date.substring(8, 10));
            let today = new (Date);
            if (startDate <= today) {
                return true;
            }
        }
        return false;
    }

    async showFilterForm() {
        this.showFilter = true;
        this.filterDisplay = "block";
    }

    async hideFilterForm(pSave: boolean) {
        if (pSave) {
            let res = await apiAccountColumnSettingUpdate({
                pageNo: this.tPageNo,
                column_setting: this.filterSelected
            });
            this.filterSelectedDefault = this.filterSelected.slice();
        }
        else {
            this.filterSelected = this.filterSelectedDefault.slice();
        }
        this.showFilter = false;
        this.filterDisplay = "none";
    }

    @Emit("form")
    showEditForm(pIndex?: number): { actionType: string, id?: number } | undefined {
        //console.log(pIndex);
        if (pIndex != undefined) {
            if (Object.prototype.hasOwnProperty.call(this.tData[pIndex], 'id')) {
                return { actionType: "update", id: this.tData[pIndex]['id'] };
            }
            //Corporation use brand_id instead id
            else if (Object.prototype.hasOwnProperty.call(this.tData[pIndex], 'brand_id')) {
                return { actionType: "update", id: this.tData[pIndex]['brand_id'] };
            }
        }
        else { //Create operation rule
            return { actionType: "create" };
        }
    }

    @Emit("form")
    async getRoute(pIndex?: number) {
        if (pIndex != undefined) {
            if (Object.prototype.hasOwnProperty.call(this.tData[pIndex], 'route_id')) {
                console.log(this.tData[pIndex])
                if (this.tData[pIndex]['行駛距離'] == null) {
                    alert("行車軌跡建立中，請稍後再進行查詢")
                    return;
                } else if (this.tData[pIndex]['行駛距離'] == 0) {
                    alert("無效座標點或僅有一個座標點，無法產生軌跡圖")
                    return;
                } else {
                    let r = JSON.parse(this.tData[pIndex]['route']);                    
                    for(let i in r){
                        if (r[i].length != 2) {
                            console.log(r);
                            console.log("Error at position: " + i, "Data: " + r[i]);
                            await tpsApiInstance.post(`/redrawRoute/${this.tData[pIndex]['route_id']}`);
                            alert("偵測到資料格式錯誤，系統將排程重建行車軌跡");
                            this.tData[pIndex]['行駛距離'] = null;
                            return;
                        }
                    } 
                    return { route: this.tData[pIndex]['route'] };
                }
            }

        }
    }

    @Emit("showRouteGoogle")
    getRouteGoogle(pIndex?: number) {
        if (pIndex != undefined) {
            if (Object.prototype.hasOwnProperty.call(this.tData[pIndex], 'route_id')) {
                console.log(this.tData[pIndex])
                if (this.tData[pIndex]['distance_g'] == null) {
                    alert("行車軌跡建立中，請稍後再進行查詢")
                    return;
                } else if (this.tData[pIndex]['distance_g'] == 0) {
                    alert("無效座標點或僅有一個座標點，無法產生軌跡圖")
                    return;
                } else {
                    return { route_g: this.tData[pIndex]['route_g'] };
                }
            }
        }
    }

    @Emit("showRouteRaw")
    getRouteRaw(pIndex?: number) {
        if (pIndex != undefined) {
            if (Object.prototype.hasOwnProperty.call(this.tData[pIndex], 'route_id')) {
                console.log(this.tData[pIndex])
                if (this.tData[pIndex]['distance_raw'] == null) {
                    alert("行車軌跡建立中，請稍後再進行查詢")
                    return;
                } else if (this.tData[pIndex]['distance_raw'] == 0) {
                    alert("無效座標點或僅有一個座標點，無法產生軌跡圖")
                    return;
                } else {
                    return { route_raw: this.tData[pIndex]['route_raw'] };
                }
            }
        }
    }

    @Emit("updateCalStatus")
    updateCalStatus(item) {
        return item;
    }
}
