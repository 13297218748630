
import { Vue } from 'vue-class-component';

export default class Alert extends Vue {
    show: boolean = false;
    alertType: string = "";
    msg: string  = "";

    doAlert(pAlertType: string, pMsg: string) {
        if(pMsg) {
            this.msg = pMsg;
            this.alertType = pAlertType;
            this.show = true;
            setTimeout(() => { this.show = false; }, 3000);
        }
    }
}
