import tpsApiInstance from "./tpsApiBase";
import {
  IBrandList,
  IBrandListName,
  IBrandDetail,
  IBrandGetListTotalNumRequestParams,
  IBrandGetListRequestParams,
  IBrandGetDetailRequestParams,
  IBrandDetailUpdateRequestParams,
  IBrandCreateResponseSchema,
  IBrandDetailUpdateResponseSchema
} from './models/brand';
import { IBrandVersionCreateRequestParams } from "./models/brand/IBrandRequestParams";
import { IBrandVersionCreateResponseSchema } from "./models/brand/IBrandResponseSchema";

const BRAND_PATH = "/brand";

export const apiBrandGetListTotalNum = async(
  pData?: IBrandGetListTotalNumRequestParams): Promise<number> => {
  let query: string = "?count=total";
  if(pData && pData.filter) query+= `&filter=${pData.filter}`;

  const res = await tpsApiInstance.get(`${BRAND_PATH}${query}`);
  console.log("total:",res?.data[0]?.total);
  return res?.data[0]?.total;
};

export const apiBrandGetList = async(
  pData?: IBrandGetListRequestParams): Promise<IBrandList | Blob> => {  
  let query: string = "?";
  if(pData && pData.limit && pData.page) query += `limit=${pData.limit}&offset=${(pData.page-1)*pData.limit}`;  
  if(pData && pData.filter) query+= `&filter=${pData.filter}`;
  
  if(pData && pData.output) {
    query += `&output=${pData.output}`;
    console.log("query:",query);
    const res = await tpsApiInstance.get(`${BRAND_PATH}${query}`,{responseType: 'blob'});
    return res?.data as Blob;
  }
  else {
    console.log("query:",query);
    const res = await tpsApiInstance.get(`${BRAND_PATH}${query}`);
    return res?.data as IBrandList;
  }
};

export const apiBrandGetListName = async(): Promise<IBrandListName> => {
    const res = await tpsApiInstance.get(BRAND_PATH);
    var listName = {};
    if(res.data) {
      var i;
      for (i = 0; i < res.data.length; i++) {
        listName[res.data[i].id] = res.data[i].name;
      }
    }
    return listName as IBrandListName;
};

export const apiBrandGetDetail = async (
  pData: IBrandGetDetailRequestParams): Promise<IBrandDetail> => {
  const res = await tpsApiInstance.get(`${BRAND_PATH}/${pData.id}`);
  console.log(res.data);
  return res.data as IBrandDetail;
};

export const apiBrandCreate = async (
  pData: IBrandDetail): Promise<IBrandCreateResponseSchema> => {
  console.log(JSON.stringify(pData));
  const res = await tpsApiInstance.post(BRAND_PATH, pData);
  console.log(res);
  return res?.data as IBrandCreateResponseSchema;
};

export const apiBrandDetailUpdate = async (
  pData: IBrandDetailUpdateRequestParams
  ): Promise<IBrandDetailUpdateResponseSchema> => {
  console.log("update:",JSON.stringify(pData));
  const res = await tpsApiInstance.post(`${BRAND_PATH}/${pData.id}`, pData.data);
  return res?.data as IBrandDetailUpdateResponseSchema;
};

export const apiBrandVersionCreate = async (
  pData: IBrandVersionCreateRequestParams
  ): Promise<IBrandVersionCreateResponseSchema> => {
  console.log("create version:",JSON.stringify(pData));
  const res = await tpsApiInstance.post(`createCompanyRuleVersion`, pData.data);
  console.log(res);
  return res?.data as IBrandVersionCreateResponseSchema;
};

